import React, { Component, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Slider from "react-slick";
import { get, chunk, flatten } from "lodash";
import media from "styled-media-query";
import { InView } from "react-intersection-observer";
import { Video, Transformation } from "cloudinary-react";
import Seo from "../components/Seo/Seo";

import "intersection-observer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Post = styled.div`
  margin: 0 0 12rem 0;
  > * {
    max-width: 500px;
  }
`;

const Title = styled.h3``;

const Text = styled.div`
  max-width: 600px;

  img {
    width: auto;
  }

  .gatsby-resp-iframe-wrapper {
    margin-bottom: 1.5rem;
  }

  ${media.lessThan("600px")`
     .gatsby-resp-iframe-wrapper {
       margin: 0 -2rem 1.5rem -2rem;
     }

     .gatsby-resp-image-wrapper {
        width: auto;
        margin: 0 -2rem !important;
        max-width: 100vw;
     }
  `}
`;

const Videos = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;

  ${media.lessThan("600px")`
    margin: 0 -2rem;
  `}

  video {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
  }
`;

const Assets = styled.div`
  position: relative;
  margin-left: -275px;
  margin-right: -2rem;
  margin-bottom: 1.5rem;
  max-width: none;

  ${media.lessThan("medium")`
     margin-left: 0;
  `}

  .slick-slide {
    padding-right: 2rem;
  }

  .slick-slide,
  .slick-slide *,
  .slick-slide:focus {
    outline: none !important;
  }

  .slick-arrow:before {
    content: "" !important;
  }

  .slick-dots {
    position: relative;
    bottom: unset;
    z-index: 101;
    margin-left: 263px;
    text-align: left;
    width: auto;
    /* margin-top: 1rem; */

    ${media.lessThan("medium")`
     margin-left: -12px;
     /* text-align: center; */
  `}

    li {
      width: 6px;
      height: 6px;
      margin: 0 12px;
      button {
        width: 6px;
        height: 6px;
        &::before {
          content: "";
          background: #000;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
`;

const Asset = styled.div``;

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: "2rem",
        bottom: "0",
        width: "10%",
        height: "100%",
        zIndex: "100",
        cursor: "e-resize",
      }}
      role="button"
      tabIndex="0"
      aria-label="Next"
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: "0",
        bottom: "0",
        width: "10%",
        height: "100%",
        zIndex: "100",
        cursor: "w-resize",
      }}
      role="button"
      tabIndex="0"
      aria-label="Previous"
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

class PostItem extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
  }

  setInfinite = (length) => {
    const assetsLength = get(this.post, "node.assets.length", 0);
    return assetsLength > length;
  };

  getSlidesToShow = (defaultLength) => {
    const assetsLength = get(this.post, "node.assets.length", 0);
    if (assetsLength < defaultLength) {
      return assetsLength;
    }
    return defaultLength;
  };

  render() {
    const { assets, videos, text } = this.post.node;

    let renderText;
    if (text) {
      renderText = (
        <Text
          dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
        />
      );
    } else {
      renderText = "";
    }

    var settings = {
      dots: true,
      infinite: this.setInfinite(4),
      speed: 250,
      slidesToShow: this.getSlidesToShow(4),
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      swipeToSlide: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: this.getSlidesToShow(3),
            slidesToScroll: 1,
            infinite: this.setInfinite(3),
            dots: true,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: this.getSlidesToShow(2),
            slidesToScroll: 1,
            infinite: this.setInfinite(2),
            dots: true,
          },
        },
      ],
    };

    return (
      <Post>
        <Title>{this.post.node.title}</Title>
        {assets && (
          <Assets>
            <Slider {...settings}>
              {assets.map((value, key) => (
                <Asset key={key}>
                  <GatsbyImage image={value.gatsbyImageData} />
                </Asset>
              ))}
            </Slider>
          </Assets>
        )}

        {videos && (
          <Videos>
            {videos.map((video) => (
              <Video
                key={video.id}
                cloudName="lord-voldemort"
                publicId={video.public_id}
                secure="true"
                autoPlay
                loop
                muted
                playsInline
              >
                <Transformation width="500" crop="limit" />
              </Video>
            ))}
          </Videos>
        )}

        {renderText}
      </Post>
    );
  }
}

const IndexPage = ({ data }) => {
  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false);
  const chunksPerPage = 5;
  const { edges } = data.allContentfulNews;
  const chunks = chunk(edges, chunksPerPage);

  const renderPosts = () => {
    const paginated = flatten(Array.from(chunks).splice(0, page));
    return paginated.map((post) => <PostItem key={post.node.id} post={post} />);
  };

  const loadMore = () => {
    setPage(page + 1);
    setAllLoaded((page + 1) * chunksPerPage >= edges.length);
  };

  return (
    <div className="recent">
      <Seo title="Recent" />
      <h2>Recent</h2>

      {renderPosts()}

      {!allLoaded && (
        <InView
          threshold={0.3}
          onChange={(inView) => {
            if (inView) {
              loadMore();
            }
          }}
        >
          <h2>Loading...</h2>
        </InView>
      )}
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query NewsQuery {
    allContentfulNews(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          videos {
            id
            public_id
          }
          assets {
            gatsbyImageData(layout: CONSTRAINED, width: 600)
          }
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
